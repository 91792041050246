import { motion } from "framer-motion";

function Projects() {
  const projects = [
    {
      name: "Lena's Friseursalon",
      leading: "Website",
      link: "https://lenas-friseursalon.de",
    },
    {
      name: "EinsatzAlarm",
      leading: "Fullstack Webapp | Cross-Platform App (iOS & Android)",
      link: "https://www.einsatz-status.net/",
    },
    {
      name: "EinsatzSysteme",
      leading: "Corporate Design | Website",
      link: "https://www.einsatz-status.net/",
    },
    {
      name: "TRICON Energy GmbH",
      leading: "Website",
      link: "https://tricon-energy.de",
    },
    { name: "Portale", leading: "Website", link: "https://portale-greven.de" },
  ];

  return (
    <motion.div
      key="projects"
      className="h-full overflow-auto pb-12-safe"
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{
        duration: 0.5,
        ease: [0.85, 0, 0.15, 1],
      }}
    >
      <div className="font-serif text-black text-4xl sm:text-xl md:text-xl mx-6 mb-6">
        Projekte
      </div>
      <div className="overflow-auto pb-32">
        {projects.map((project) => (
          <div
            onClick={() => window.open(project.link, "_blank")}
            className="p-6 cursor-pointer hover:bg-black transition-all group"
          >
            <p className="font-serif italic text-neutral-500 group-hover:text-neutral-300 text-sm xl:text-base">
              {project.leading}
            </p>
            <div className="flex items-center align-middle gap-4">
              <h2 className="text-neutral-800 group-hover:text-neutral-100 text-xl xl:text-3xl ">
                {project.name}
              </h2>
              <motion.svg
                initial={{ scaleX: 2 }}
                animate={{ scaleX: 1 }}
                transition={{
                  duration: 0.5,
                  ease: [0.85, 0, 0.15, 1],
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 group-hover:text-neutral-100 group-hover:scale-x-150 transition-all"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </motion.svg>
            </div>
            <hr className="divide-neutral-800" />
          </div>
        ))}
      </div>
    </motion.div>
  );
}

export default Projects;
