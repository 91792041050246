import { motion } from "framer-motion";

function Contact() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{
        duration: 0.5,
        ease: [0.85, 0, 0.15, 1],
      }}
    >
      <div className="font-serif text-black text-4xl sm:text-xl md:text-xl mx-6 mb-6">
        Kontakt
      </div>
      <div className="flex flex-col justify-around min-h-full mx-6">
        <p className="font-bold text-gray-500 text-sm">E-Mail</p>
        <a href="mailto:info@tillianbo.com?subject=Anfrage über Website&body=Ihre Nachricht">
          info@tillianbo.com
        </a>
      </div>
    </motion.div>
  );
}

export default Contact;
