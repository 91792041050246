import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Projects from "./Projects";
import Start from "./Start";
import UIWrapper from "./UIWrapper";

import { AnimatePresence } from "framer-motion";
import Contact from "./Contact";
import About from "./About";
import Imprint from "./Imprint";
import Privacy from "./Privacy";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<UIWrapper />}>
          <Route path="/" element={<Start />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="privacy" element={<Privacy />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
