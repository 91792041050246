import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Imprint() {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      className="h-full overflow-auto pb-12-safe"
      transition={{
        duration: 0.5,
        ease: [0.85, 0, 0.15, 1],
      }}
    >
      <div className="font-serif text-black text-4xl sm:text-xl md:text-xl mx-6 mb-6">
        Impressum
      </div>
      <div className="overflow-auto mx-6">
        <h2 className="font-bold">Diensteanbieter</h2>
        <p>Tillian Borchmann</p>
        <p>Marktstr. 38</p>
        <p>48268 Greven</p>
        <p>Deutschland</p>
        <br />
        <h2 className="font-bold">Kontaktmöglichkeiten</h2>E-Mail-Adresse:{" "}
        <p>
          <a href="mailto:info@tillianbo.com">info@tillianbo.com</a>
        </p>
        Kontaktformular:{" "}
        <p>
          <button
            className="decoration-2 underline decoration-neutral-500"
            onClick={() => navigate("/contact")}
          >
            https://tillianbo.com/contact
          </button>
        </p>
        <br />
        <h2 className="font-bold">Angaben zum Unternehmen</h2>Umsatzsteuer
        Identifikationsnummer (USt-ID): <p> DE334599349</p>
        <br />
        <h2 className="font-bold">Online-Streitbeilegung (OS)</h2>
        <p>
          Online-Streitbeilegung: Die Europäische Kommission stellt eine
          Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          finden. Verbraucher haben die Möglichkeit, diese Plattform für die
          Beilegung ihrer Streitigkeiten zu nutzen.{" "}
        </p>
        <br />
        <h2 className="font-bold">Haftungs- und Schutzrechtshinweise</h2>
        <p>
          Links auf fremde Webseiten: Die Inhalte fremder Webseiten, auf die wir
          direkt oder indirekt verweisen, liegen außerhalb unseres
          Verantwortungsbereiches und wir machen sie uns nicht zu Eigen. Für
          alle Inhalte und Nachteile, die aus der Nutzung der in den verlinkten
          Webseiten aufrufbaren Informationen entstehen, übernehmen wir keine
          Verantwortung.
        </p>
        <br />
        <p>
          Urheberrechte und Markenrechte: Alle auf dieser Website dargestellten
          Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen
          sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte)
          geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren
          Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteinhaber.
        </p>
        <br />
        <p>
          Hinweise auf Rechtsverstöße: Sollten Sie innerhalb unseres
          Internetauftritts Rechtsverstöße bemerken, bitten wir Sie uns auf
          diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach
          Kenntnisnahme unverzüglich entfernen.
        </p>
        <br />
        <h2 className="font-bold">Bildnachweise</h2>Bildquellen und
        Urheberrechtshinweise:{" "}
        <p>
          Einige Bildinhalte wurden über{" "}
          <a href="https://unsplash.com/" target="_blank" rel="noreferrer">
            https://unsplash.com/
          </a>{" "}
          bezogen.
        </p>
        <p class="seal">
          <a
            href="https://datenschutz-generator.de/"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
            Schwenke
          </a>
        </p>
      </div>
    </motion.div>
  );
}

export default Imprint;
