import { motion } from "framer-motion";

const { useNavigate } = require("react-router-dom");

function NavigationLink(props) {
  const navigate = useNavigate();
  return (
    <motion.button
      className="basic-1"
      onClick={() => navigate("/" + props.btnTo)}
    >
      {props.btnTitle}
    </motion.button>
  );
}

export default NavigationLink;
